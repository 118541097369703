@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background: #fafafa;
}

nav {
  height: 75px;
  color: blue;
}
h1 {
  padding-top: 100px;
  font-size: 50px;
}
nav ul li a {
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0px 32px;
}

.navbar-brand {
  font-size: 30px;
  font-family: 'Great Vibes', cursive;
}

.nav-link:hover {
  color: aqua;
}

.about {
  padding-top: 9rem;
  padding-bottom: 120px;
}
.about-me {
  font-size: 20px;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(20, 19, 19);
}

.form {
  border: 1px solid #a1a1a4;
  padding: 3rem;
  margin-right: 3%;
}
a {
  text-decoration: none;
  color: #303030;
}
a:hover {
  color: #a1a1a4;
}
.fa-brands {
  font-size: 2rem;
}

.submit {
  width: 100%;
  background-color: #303030;
  color: #fafafa;
}

.submit:hover {
  color: white;
}

.form-control:focus {
  border-color: #303030 !important;
  box-shadow: 0 0 5px #303030 !important;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 12.5%;
  text-align: center;
}

.container {
  max-width: 75%;
}

.code {
  background-color: #303030;
  color: #fafafa;
}

.code:hover {
  background-color: #fafafa;
  color: #303030;
}

.description,
.work {
  font-size: 18px;
}
.fa-brands {
  padding-right: 1rem;
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 60%;
  }
  .footer {
    left: 20%;
  }
}
@media screen and (max-width: 990px) {
  .work {
    text-align: center !important;
  }
  .form {
    margin: auto;
  }
  .about-me {
    padding-top: 3rem;
  }
}

.highlight {
  color: rgb(67, 172, 172);
}

.Loader_cf__2I9Cs {
  *zoom: 1;
}
.Loader_loader__TLVE4 {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  padding-top: 20px;
}

#Loader_loader1__1Ugtc:before,
#Loader_loader1__1Ugtc:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #101113;
}

#Loader_loader1__1Ugtc:before {
  z-index: 100;
  animation: Loader_spin__2tXru 1s infinite;
}

#Loader_loader1__1Ugtc:after {
  border: 10px solid #ccc;
}

@keyframes Loader_spin__2tXru {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

